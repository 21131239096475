<template>
    <div class="zj-button-jack-up" :class="active ? 'active' : ''" @click="onPress">
        <div class="image" :class="flicker ? 'flicker' : ''" >
            <div class="circle"></div>
            <img class="icon" :src="icon" alt="">
        </div>
        <div class="name">{{name}}</div>
    </div>
</template>

<script>
export default {
    name: 'zjButtonJackUp',
    props: {
        icon: {
            type: String,
            default: require("./icon-soldier.svg")
        },
        name: {
            type: String,
            default: '托起按钮'
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            flicker: false, //定时闪动动画
        }
    },
    mounted(){
        setInterval( ()=>{ 
            this.flicker = true;
            setTimeout( () => {this.flicker = false; }, 500 )
        }, 3000 );
    },
    methods:{
        onPress(){
            this.$emit('press')
        },
    },
    watch: {
        active(val){
            console.log("active", val)
        },
    },
}
</script>

<style>
/* 基础效果 */
.zj-button-jack-up{display: flex; width: 80px; height: 100px; flex-direction: column; justify-content: center; align-items: center; cursor: pointer; }
.zj-button-jack-up .image{display: flex; width: 80px; height: 80px; justify-content: center; align-items: center; background: url("./icon-light-up.svg") bottom center no-repeat; background-size: 100% auto; position: relative; transition: all 0.5s 0s;}
.zj-button-jack-up .image .circle{display: flex; width: 50px; height: 50px; justify-content: center; align-items: center; background: url("./icon-circle.svg") center center; border-radius: 50%; background-size: 100% auto; box-shadow: #40C0FF 0 0 2px; transform: rotate(45deg); transition: all 0.5s cubic-bezier(.5, -1,.25,2) 0s; z-index: 1; }
.zj-button-jack-up .image .icon{top:26px; left:26px; width: auto; height: 27.5px;  transition: all 0.5s cubic-bezier(.5, -1,.25,2) 0s; position: absolute; z-index: 2; }
.zj-button-jack-up .name{height: 20px; line-height: 20px; color: #40C0FF; font-family: "microsoft yahei"; font-size: 12px; font-weight: bold;}

/* 按钮激活。向上弹跳效果 */
.zj-button-jack-up.active{}
.zj-button-jack-up.active .image{background-image: url("./icon-light-up-active.svg");}
.zj-button-jack-up.active .image .circle{margin: -15px 0 0 0; background-image: url("./icon-circle-active.svg"); transform: rotate(360deg); box-shadow: #40E090 0 0 10px;}
.zj-button-jack-up.active .image .icon{margin: -7.5px 0 0 0;}
.zj-button-jack-up.active .name{ color: #40E090;}

/* 鼠标移过。高速旋转效果 */
.zj-button-jack-up:hover .image .circle,.zj-button-jack-up.active:hover .image .circle{transform: rotate(-315deg); box-shadow: #40C0FF 0 0 10px; }

/* 定时动画。微量旋转效果（未激活则不动） */
.zj-button-jack-up .image.flicker .circle{transform: rotate(45deg); }

</style>
