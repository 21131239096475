<!-- Singleton Pattern -->
<template>
    <div class="com-navigator" :class="showNav ? '' : 'minimize'">
        <div class="nav-list" :class="showNav ? '' : 'minimize'">
            <template v-for="(it,index) in navList" >
                <zj-button-jack-up :name="it.name" :icon="it.icon" :active.sync="it.active" @press="onNav(it)" />
            </template>
            <div class="nav-min" title="最小化" v-if="showNav" @click="showNav=false" ><img src="@/static/icons/nav/icon-min-left-bottom.svg" alt=""></div>
        </div>
        <!-- 导航栏收起的时候显示的图标 -->
        <div class="restore" v-if="!showNav" title="显示资源导航条" @click="showNav=!showNav"><img src="@/static/icons/nav/icon-more-box.svg" alt=""></div>
    </div>
</template>

<script>
import ZjButtonJackUp from '@/components/zj-button-jack-up/zj-button-jack-up.vue'

export default {
    name: 'ComNav',
    components: { 
        ZjButtonJackUp,
    },
    data(){
        return {
            showNav: true,
            navList:[
                {
                    key: 'soldiers',
                    icon: require("@/static/icons/nav/icon-soldier.svg"),
                    name: '单兵',
                    active: true
                },
                {
                    key: 'rescuers',
                    icon: require("@/static/icons/nav/icon-auxilium.svg"),
                    name: '救援队伍',
                    active: false
                },
                {
                    key: 'cartons',
                    icon: require("@/static/icons/nav/icon-carton.svg"),
                    name: '物资设备',
                    active: false
                },
                {
                    key: 'warehouses',
                    icon: require("@/static/icons/nav/icon-warehouse.svg"),
                    name: '应急仓库',
                    active: false
                },
                {
                    key: 'locations',
                    icon: require("@/static/icons/nav/icon-exitus.svg"),
                    name: '避难场所',
                    active: false
                },
                {
                    key: 'electrics',
                    icon: require("@/static/icons/nav/icon-warning.svg"),
                    name: '重大危险源',
                    active: false
                },
                {
                    key: 'radars',
                    icon: require("@/static/icons/nav/icon-protect.svg"),
                    name: '重点防护目标',
                    active: false
                },
                {
                    key: 'mavins',
                    icon: require("@/static/icons/nav/icon-mavin.svg"),
                    name: '专家',
                    active: false
                },
                {
                    key: 'monitors',
                    icon: require("@/static/icons/nav/icon-monitor.svg"),
                    name: '监控设备',
                    active: false
                },
                {
                    key: 'waters',
                    icon: require("@/static/icons/nav/icon-water-engine.svg"),
                    name: '水利工程',
                    active: false
                },
                {
                    key: 'medicals',
                    icon: require("@/static/icons/nav/icon-medical.svg"),
                    name: '医疗卫生',
                    active: false
                },
                {
                    key: 'buildings',
                    icon: require("@/static/icons/nav/icon-build.svg"),
                    name: '文体旅游场所',
                    active: false
                },
                {
                    key: 'schools',
                    icon: require("@/static/icons/nav/icon-school.svg"),
                    name: '学校',
                    active: false
                },
                {
                    key: 'others',
                    icon: require("@/static/icons/nav/icon-other.svg"),
                    name: '其它',
                    active: false
                },
            ],
            
            
        }
    },
	computed:{
		modMarkers(){
			// console.log("stateMarkers: ", this.$store.state.modMarkers)
			return this.$store.state.modMarkers
		}
	},
    mounted(){
        // console.log("mounted 1: " + this.$store.state.pi)
        this.$store.registerModule('modBaiduMarkers', {
            
        })
        // console.log("mounted 2: " + JSON.stringify(this.$store.state.modBaidu))
        this.initNavStatus(this.modMarkers);
    },
    methods: {
        initNavStatus(list){
            for(const key in list){
                // console.log('initNavStatus 1: ' + JSON.stringify(list[key]))
                const pos = this.navList.findIndex( it => it.key === key);
                if(pos >= 0){
                    // console.log('initNavStatus 2: ' + JSON.stringify(pos) + '; key:' + key)
                    this.navList[pos].active = list[key]['show'];
                }
            }
            
        },
        onNav(item){
            item.active = !item.active;
            
			// if(item.active)
			// 	this.$store.dispatch('showMarkers', item.key);
			// else
			// 	this.$store.dispatch('hideMarkers', item.key);
				
            // this.$store.commit('toggleGroup', item.key )
        },
        
    },
}
</script>

<style>

.com-navigator{max-width: calc(100%); margin: 0 auto; padding: 0; top: 0; position: relative; z-index: 2; transition: all 0.2s 0s; border: 0px solid rgba(69,192,251,1); }
.com-navigator:hover{background: rgba(0,0,0,0.2); }
.com-navigator.minimize{width: 50px; height: 50px; padding: 0; }
/* .com-navigator{display: flex; width: 100%; left:0; bottom: 20px; justify-content: center; position: absolute; z-index: 2;} */
.com-navigator .nav-list{display: flex; width: 100%; padding: 0; justify-content: space-between; border: 0 #f00 solid; transition: all 0.5s 0s; overflow-x: scroll; overflow-y: hidden;}
.com-navigator .nav-list.minimize{width: 0; overflow: hidden;}
.com-navigator .nav-list .nav-min{top:0; right: 0; width: 15px; height: 15px; padding: 2px; transition: all 0.2s 0s; cursor: pointer; border-radius: 2px; position: absolute; z-index: 2; }
.com-navigator .nav-list .nav-min img{width: 100%; height: 100%;}
.com-navigator .nav-list .nav-min:hover{background: #0070AD;}

.com-navigator .restore{left:0; bottom: 0; padding: 5px; width: 40px; height: 40px; border-radius: 5px; transition: all 0.2s 0s; cursor: pointer; position: absolute; }
.com-navigator .restore:hover{background: #0070AD;}
.com-navigator .restore img{width: auto; height: 100%;}


/*滚动条整体样式*/ /*高宽分别对应横竖滚动条的尺寸*/
.com-navigator .nav-list::-webkit-scrollbar { width : 5px;  height: 5px;  }
/*滚动条里面小方块，能向上向下移动（或向左向右移动）*/
.com-navigator .nav-list::-webkit-scrollbar-thumb { border-radius: 5px; box-shadow : inset 0 0 5px transparent; background : rgba(127,127,127,0); transition: all 0.5s 0s; }
.com-navigator .nav-list:hover::-webkit-scrollbar-thumb  { background : rgba(127,127,127,0.5); }
/*滚动条的轨道（里面装有Thumb）*/
.com-navigator .nav-list::-webkit-scrollbar-track { border-radius: 5px; box-shadow : inset 0 0 5px transparent; background : rgba(127,127,127,0); }
.com-navigator .nav-list:hover::-webkit-scrollbar-track  { background : rgba(127,127,127,0.2); }
/* 滚动条的轨道的两端按钮，由于通过点击微调小方块的位置。*/
.com-navigator .nav-list::-webkit-scrollbar-button {}
/*内层轨道，滚动条中间部分*/
.com-navigator .nav-list::-webkit-scrollbar-track-piece {}
/*边角，即垂直滚动条和水平滚动条相交的地方*/
.com-navigator .nav-list::-webkit-scrollbar-corner {border-radius: 5px; background : rgba(127,127,127,0.2);}
/*两个滚动条的交汇处上用于拖动调整元素大小的小控件*/
.com-navigator .nav-list::-webkit-resizer {}
</style>